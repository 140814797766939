import { ApiChat } from "../../utils/axios";

const endpointV1 = '/api/v1'

export async function authChat(data) {
  const response = await ApiChat.post(
    `${endpointV1}/user/login_or_register`, data
  );
  return response;
}

export async function createRoom(data) {
  const response = await ApiChat.post(
    `${endpointV1}/chat/create_room`, data
  );
  return response;
}

export async function sendMessage(data) {
  const response = await ApiChat.post(
    `${endpointV1}/chat/send_message`, data
  );
  return response;
}

export async function sendMessageAttachment(data) {
  const strImg = data.url_image.replace(/^data:image\/[a-z]+;base64,/, "")
  const response = await ApiChat.post(
    `${endpointV1}/chat/upload_image`, {...data, url_image: strImg}
  );
  return response;
}

export async function receiveMessage(params) {
  const response = await ApiChat.get(
    `${endpointV1}/chat/receive_message/${params.room_id}`, {
      params
    }
  );
  return response;
}

export async function getListUserRoom(params) {
  const response = await ApiChat.get(
    `${endpointV1}/chat/get_user_rooms`, {
    params: {
      ...params,
      show_participants: true,
      room_type: 'group'
    }
  });
  return response;
}

export async function getRoomParticipants(data) {
  const response = await ApiChat.post(
    `${endpointV1}/user/room_participants`, data
  );
  return response;
}

export async function getUnreadMessage(data) {
  const response = await ApiChat.post(
    `${endpointV1}/chat/get_unread_count`, data
  );
  return response;
}
