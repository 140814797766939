import axios from "axios";
import token from "../components/shared/constants/Token";
import expiredStorage from "./ExpiredStorage";

const client = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
  headers: {
    token: `${token}`,
  },
});

const tokenChat = expiredStorage.getItem('@tokenChat')

export const ApiChat = axios.create({
  baseURL: `${process.env.REACT_APP_API_CHAT_URL}`,
  headers:{
    Authorization: `Bearer ${tokenChat}`
  }
});

export default client;
