import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.css";
import "./scss/base.scss";
import App from "./App";
import fontawesome from "./plugin/fontawesome"; // eslint-disable-line no-unused-vars
import reportWebVitals from "./reportWebVitals";
import "leaflet/dist/leaflet.css";

// init redux
import { Provider } from 'react-redux';

import store from './store';
import qiscus from './plugin/qiscus';

window.addEventListener('DOMContentLoaded', () => {
  qiscus.init({
      AppId: 'qchatsdk--vlv0so6zyia'
  })
})

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>, 
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
