import { combineReducers } from 'redux';

import chat from './chat/reducer';

const createRootReducer = () =>
  combineReducers({
    chat
  });

export default createRootReducer;
