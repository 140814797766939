import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAddressBook,
  faBars,
  faBell,
  faFile,
  faHome,
  faTimes,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faBell,
  faBars,
  faHome,
  faFile,
  faAddressBook,
  faUserCircle,
  faTimes
);

export default library;
