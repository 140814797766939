import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
  getUnreadMessage,
  getListUserRoom
} from '../../../components/services/ChatServices';
import actions from './actions';

export function* GET_UNREAD_MESSAGE({ params }) {
  yield put({ type: actions.SET_STATE, payload: { loading: true, unreadChat: [], totalUnread: 0 } });

  try {
    const { data } = yield call(getUnreadMessage, params);
    const dataUnread = data?.response_data?.results?.unread_counts || []
    const totalUnread = dataUnread.map((res) => res.unread_count).reduce((res, val) => res + val)
    yield put({ type: actions.SET_STATE, payload: { unreadChat: dataUnread, totalUnread: totalUnread && totalUnread } });
  } catch ({ message: error }) {
    yield put({ type: actions.SET_STATE, payload: { error: `GET_UNREAD_MESSAGE - ${error}` } });
  } finally {
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

export function* GET_ALL_ROOMS() {
  const params = {
    limit: 100,
    room_type: 'group'
  }
  yield put({ type: actions.SET_STATE, payload: { loading: true, allChatRooms: [] } });

  try {
    const { data } = yield call(getListUserRoom, params);
    const rooms = data?.response_data?.results?.rooms || []
    const allChatRooms = rooms.map((room) => {
      const splitName = room.room_name.split('/')
      room.room_name = splitName[0]
      return room
    })
    yield put({ type: actions.SET_STATE, payload: { allChatRooms  } });
  } catch ({ message: error }) {
    yield put({ type: actions.SET_STATE, payload: { error: `GET_ALL_ROOMS - ${error}` } });
  } finally {
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}


export default function* chatSagas() {
  yield all([
    takeEvery(actions.GET_UNREAD_MESSAGE, GET_UNREAD_MESSAGE),
    takeEvery(actions.GET_ALL_ROOMS, GET_ALL_ROOMS),
  ]);
}
