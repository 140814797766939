import { lazy, Suspense, useEffect } from "react";
import { Route, Router, Switch } from "react-router-dom";
import { generateCidSecret, getToken } from "./components/services/CidService";
import loadFontAwesome from "./components/shared/font-awesome/loader";
import { history } from "./history";
import expiredStorage from "./utils/ExpiredStorage";

const Page = lazy(() => import("./containers/Page"));
const Login = lazy(() => import("./containers/LoginContainer"));

loadFontAwesome();
function App() {
  const loading = () => (
    <div className="animated fadeIn pt-1 text-center">Loading...</div>
  );
  const tokenStorage = expiredStorage.getItem("@token");

  useEffect(() => {
    if (!expiredStorage.getItem("@token")) {
      generateCidSecret().then((res) => {
        getToken(res.data.Data).then((res) => {
          expiredStorage.setItem("@token", res.data.Data.token, 3595);
          window.location.reload();
        });
      });
    }
    const token = setInterval(() => {
      if (!expiredStorage.getItem("@token")) {
        generateCidSecret().then((res) => {
          getToken(res.data.Data).then((res) => {
            expiredStorage.setItem("@token", res.data.Data.token, 3595);
          });
        });
      }
    }, 350000);

    return () => clearInterval(token);
  }, [tokenStorage]);

  return (
    <Suspense fallback={loading}>
      <Router history={history}>
        <Switch>
          <Route exact path="/login" render={(props) => <Login {...props} />} />
          <Route path="/" render={(props) => <Page {...props} />} />
        </Switch>
      </Router>
    </Suspense>
  );
}

export default App;
